<script setup lang="ts">
import {
	getOptimizedSrc,
	getGridItemSrcset,
	getGridItemSizes,
} from '@zyro-inc/site-modules/utils/getSrcsets';

import {
	computed,
	onMounted,
	ref,
	watch,
} from 'vue';

interface Props {
	alt?: string;
	src: string;
	isEager?: boolean;
	objectFit?: string;
	width?: number;
	height?: number;
	enableSrcset?: boolean;
	imageOrigin?: string;
	isLossless?: boolean;
	siteId?: string;
	secondaryImageSrc?: string | null;
}

const props = withDefaults(defineProps<Props>(), {
	isEager: false,
	objectFit: 'cover',
	width: 0,
	height: 0,
	enableSrcset: false,
	imageOrigin: 'other',
	isLossless: false,
	siteId: '',
});

const imageUrl = ref(props.src);
const srcset = ref<string>();
const isLoaded = ref(false);

const sizes = computed(() => (props.enableSrcset ? getGridItemSizes(props.width) : undefined));

const getImageUrl = (url: string) => getOptimizedSrc(props.imageOrigin, url, props.siteId, {
	width: props.width,
	height: props.height,
	shouldContain: props.objectFit === 'contain',
	isLossless: props.isLossless,
});

const getSrcSet = (url: string) => (props.enableSrcset ? getGridItemSrcset(props.imageOrigin, url, props.siteId, {
	width: props.width,
	height: props.height,
	shouldContain: props.objectFit === 'contain',
	isLossless: props.isLossless,
}) : undefined);

const preloadSecondaryImage = () => {
	// Preload secondary image, so it's instant on hover
	if (props.secondaryImageSrc) {
		const img = new Image();
		const imgSrcset = getSrcSet(props.secondaryImageSrc);

		img.src = getImageUrl(props.secondaryImageSrc);

		if (imgSrcset) {
			img.srcset = imgSrcset;
		}
	}
};

watch(() => props.objectFit, (newValue, oldValue) => {
	// watch objectFit values and fetch new one only if the image has property crop
	// so the new value would be re-fitted with full size into the image frame
	const wasCoverObjectFit = oldValue === 'cover';
	const isDifferentObjectFit = newValue !== oldValue;

	if (isDifferentObjectFit && wasCoverObjectFit && imageUrl.value.includes('fit=crop')) {
		imageUrl.value = getImageUrl(props.src);
		srcset.value = getSrcSet(props.src);
	}
});

// `src` can change on product hover or when changing image hover effect value
watch(() => props.src, () => {
	imageUrl.value = getImageUrl(props.src);
	srcset.value = getSrcSet(props.src);
});

watch(() => props.secondaryImageSrc, () => {
	preloadSecondaryImage();
});

onMounted(() => {
	imageUrl.value = getImageUrl(props.src);
	srcset.value = getSrcSet(props.src);
	preloadSecondaryImage();
});
</script>

<template>
	<Transition
		name="fade"
		mode="out-in"
	>
		<img
			v-show="true"
			v-bind="$attrs"
			:loading="isEager ? 'eager': 'lazy'"
			:src="imageUrl"
			:alt="alt"
			:srcset="srcset"
			:sizes="sizes"
			class="ecommerce-product-image"
			:class="{ 'ecommerce-product-image--loading': !isLoaded }"
			@load="isLoaded = true"
			@error="isLoaded = true"
		>
	</Transition>
</template>

<style lang="scss">
.ecommerce-product-image {
	&--loading {
		visibility: hidden;
	}
}
</style>
